import { generateNonce } from "@/utils/util";
import { APPLICATION_MODE } from "@/utils/const";
import i18n from "@/plugins/i18n";

export default {
  methods: {
    async reSendTwoFactor() {
      this.secondFactorNonce = generateNonce();
      let request = {
        nonce: this.secondFactorNonce,
        userId: this.userId,
      };
      let response = await this.$store.dispatch(
        "session/resendTwoFactor",
        request
      );
      this.secondFactorId = response.secondFactorResponse.secondFactorId;
      this.timer = 29;
      this.startCountDown();
    },
    startCountDown() {
      let countDown = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          clearInterval(countDown);
        }
      }, 1000);
    },

    async finishRegistration(registration, isInvited = false) {
      await this.clearStore();
      await this.$store.dispatch("session/finishRegistration", registration);
      await this.$store.dispatch("profile/updateStatus", {
        status: "REGISTERED",
        result: "SUCCESS",
      });
      await this.$store.dispatch("profile/updateMortgageStatus", {
        status: isInvited ? "INVITED" : "REGISTERED",
        result: "SUCCESS",
      });
      if (isInvited) {
        await this.$store.dispatch(
          "profile/updateApplicationMode",
          APPLICATION_MODE.MORTGAGE
        );
      }
      await this.fetchOnLogin();
    },
    async bypassTwoFactor(loginResponse, mortgage = false) {
      await this.clearStore();

      let redirect = await this.$store.dispatch(
        mortgage ? "profile/setMortgageStatus" : "profile/setStatus",
        loginResponse
      );

      await this.fetchOnLogin();

      return redirect;
    },

    async finishLogin(solution) {
      await this.clearStore();

      let loginResponse = await this.$store.dispatch(
        "session/finishLogin",
        solution
      );

      let redirect = "/";

      if (loginResponse.currentApplicationMode === APPLICATION_MODE.LOAN) {
        redirect = await this.$store.dispatch(
          "profile/setStatus",
          loginResponse
        );
      } else if (
        loginResponse.currentApplicationMode === APPLICATION_MODE.MORTGAGE
      ) {
        redirect = await this.$store.dispatch(
          "profile/setMortgageStatus",
          loginResponse
        );
      }

      await this.fetchOnLogin();

      return redirect;
    },
    async finishPasswordChange(solution) {
      await this.clearStore();

      let chngResponse = await this.$store.dispatch(
        "session/finishPwdChange",
        solution
      );

      if (!this.$store.getters["session/isLoggedIn"]) {
        this.$buefy.toast.open({
          duration: 5000,
          message: i18n.t(
            "af:forgot_password.modal.message.change_success_log_in"
          ),
          position: "is-bottom",
          type: "is-success",
        });
        return "/";
      }

      let redirect = "/";

      if (chngResponse.currentApplicationMode === APPLICATION_MODE.LOAN) {
        redirect = await this.$store.dispatch(
          "profile/setStatus",
          chngResponse
        );
      } else if (
        chngResponse.currentApplicationMode === APPLICATION_MODE.MORTGAGE
      ) {
        redirect = await this.$store.dispatch(
          "profile/setMortgageStatus",
          chngResponse
        );
      }

      await this.fetchOnLogin();

      return redirect;
    },
    async fetchOnLogin() {
      await this.$store.dispatch("myTodo/fetchCount");
      await this.$store.dispatch("myTodo/fetchMortgageCount");
      await this.$store.dispatch("purchase/hasActivePurchase");
    },
    async clearStore() {
      await this.$store.dispatch("myTodo/clear");
      await this.$store.dispatch("purchase/clear");
    },
  },
};
