<template>
  <form class="af-card" @submit.prevent="doSubmit" autocomplete="new-password">
    <div class="p-3">
      <div class="is-size-4 has-text-grey-icon">
        <span v-if="!!customerFirstName">
          {{
            $t("af:login.second_factor.title.hello") +
            " " +
            customerFirstName +
            "!"
          }}
        </span>
        <span v-else>
          {{ $t("af:login.second_factor.title.hello") + "!" }}
        </span>
      </div>
      <div class="is-size-3 has-text-weight-bold mt-1 mb-4">
        {{ $t("af:login.second_factor.heading") }}
      </div>
      <div class="has-text-grey mb-5">
        {{ $t("af:login.second_factor.description") }}
      </div>

      <validation-observer ref="codeObserver">
        <BInputWithValidation
          :label="$t('af:login.second_factor.code.label')"
          rules="required"
          label-position="on-border"
          autocomplete="new-password"
          :name="name"
          v-model="code"
          id="loginSecondFactorCode"
        />
      </validation-observer>

      <b-field>
        <b-button
          type="is-blue"
          native-type="submit"
          expanded
          :loading="loading"
          id="loginSecondFactorSubmit"
          >{{ $t("af:login.second_factor.button.next") }}</b-button
        >
      </b-field>
      <p
        v-if="timer === 0"
        @click="reSendTwoFactor()"
        class="has-text-centered has-text-weight-bold is-link resend-button"
        id="loginSecondFactorResend"
      >
        {{ $t("af:login.second_factor.resend_code.link") }}
      </p>
      <p v-else class="has-text-centered has-text-grey">
        <b-icon icon-pack="fa" icon="info-circle"></b-icon>
        <span class="has-text-grey-icon is-size-6">{{
          timer +
          $t("af:login.second_factor.resend_code.can_resend_after_seconds")
        }}</span>
      </p>
    </div>

    <b-modal v-model="showApplicationInProgressModal" :can-cancel="false">
      <ApplicationInProgressModal @accept="acceptModal" />
    </b-modal>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import {
  getActiveLandingParams,
  removeActiveLandingParam,
} from "@/utils/landingParamUtil";
import twoFactorMixin from "../mixins/twoFactorMixin";

export default {
  name: "LoginSecondFactor",
  title: "af:page.login.second_factor.title",
  mixins: [twoFactorMixin],
  components: {
    ApplicationInProgressModal: () =>
      import("@/components/ApplicationInProgressModal.vue"),
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
  },
  data() {
    return {
      userId: null,
      code: "",
      secondFactorId: "",
      secondFactorNonce: "",
      name: (Math.random() + 1).toString(36).substring(5),
      customerFirstName: null,
      loading: false,
      timer: 29,
      showApplicationInProgressModal: false,
      redirect: "/",
    };
  },
  computed: {
    ...mapGetters({
      secondFactor: "session/secondFactor",
      loggedIn: "session/isReady",
      hasActiveMortgageApplication: "profile/hasActiveMortgageApplication",
    }),
    arrivedWithMortgageExternalId() {
      let landingParams = getActiveLandingParams();
      return landingParams
        ? !!JSON.parse(landingParams).mortgageExternalId
        : false;
    },
  },
  async mounted() {
    if (this.loggedIn) {
      await this.$router.push("/user/profile");
    } else if (!this.secondFactor) {
      await this.$router.push("/login");
    } else {
      this.secondFactorId = this.secondFactor.id;
      this.secondFactorNonce = this.secondFactor.nonce;
      this.customerFirstName = this.secondFactor.customerFirstName;
      this.userId = this.secondFactor.userId;

      this.startCountDown();
      await this.$store.dispatch("session/deleteSecondFactor");
    }
  },
  methods: {
    async doSubmit() {
      try {
        const valid = await this.$refs.codeObserver.validate();
        if (!valid) return;
        this.loading = true;

        this.track();

        let solution = {
          id: this.secondFactorId,
          code: this.code,
          nonce: this.secondFactorNonce,
          landingParams: getActiveLandingParams(),
        };

        this.redirect = await this.finishLogin(solution);

        const initalPath = sessionStorage.getItem("INITIAL_PATH");
        sessionStorage.removeItem("INITIAL_PATH");
        if (initalPath) {
          this.redirect = initalPath;
        }

        // clearLandingParams();

        if (
          this.arrivedWithMortgageExternalId &&
          this.hasActiveMortgageApplication
        ) {
          this.showApplicationInProgressModal = true;
        } else {
          await this.doRedirect();
        }
      } catch (ex) {
        this.$buefy.toast.open({
          message: i18n.t("af:login.error.general"),
          type: "is-danger",
        });
        console.error(ex);
      }

      this.loading = false;
    },
    track() {
      this.$gtag.event("login_2fa");
    },
    async acceptModal() {
      this.showApplicationInProgressModal = false;
      await this.doRedirect();
    },
    async doRedirect() {
      removeActiveLandingParam("mortgageExternalId");
      await this.$router.push(this.redirect);
    },
  },
};
</script>

<style scoped lang="scss">
.af-card {
  background: white;
  border-radius: 16px;
  padding: 20px;
}

@media only screen and (min-width: 769px) {
  .af-card {
    padding: 32px 36px;
  }
}

.is-link {
  color: #1072fc;
  cursor: pointer;
}

.mbh {
  .resend-button {
    color: #8f1c6b !important;
  }
}
</style>
